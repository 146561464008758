import { Modal, Table, Tag } from "antd";
import React, { useState } from "react";
import { useBoolean } from "usehooks-ts";
import { useTopFaultCodes } from "../../../hooks/StatisticsHooks";
import { MonthlyQueriesByFaultCodeVisualization } from "../MonthlyQueriesByUserFaultCode/MonthlyQueriesByFaultCodeVisualization";

/**
 * To draw the total fault code table
 */
export const TopFaultCodesTable = ({ startDate, endDate }) => {
  const { data, loading } = useTopFaultCodes();
  const [selectedFaultCode, setSelectedFaultCode] = useState(null);
  const { value: modalVisible, setTrue: showModal, setFalse: hideModal } = useBoolean();

  const onFaultCodeClick = (faultCode) => {
    setSelectedFaultCode(faultCode);
    showModal();
  };

  return (
    <>
      <Table dataSource={data} rowKey="code" loading={loading}>
        <Table.Column
          title="Fault Code"
          width="40%"
          dataIndex="code"
          render={(faultCode) => (
            <Tag color="green" style={{ cursor: "pointer" }} onClick={() => onFaultCodeClick(faultCode)}>
              {faultCode}
            </Tag>
          )}
        />
        <Table.Column title="Number of times" dataIndex="numberOfTimes" width="30%" />
        <Table.Column title="Percentage" dataIndex="percentage" width="30%" />
      </Table>
      {modalVisible && (
        <Modal visible={modalVisible} width={"100%"} footer={null} onCancel={hideModal}>
          <MonthlyQueriesByFaultCodeVisualization
            faultCode={selectedFaultCode}
            startDate={startDate}
            endDate={endDate}
          />
        </Modal>
      )}
    </>
  );
};
