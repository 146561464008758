import { Col, Form, Input, message, Modal, Row, Spin, Typography } from "antd";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useStores } from "../../../stores";

const { Text } = Typography;

const getItemStyle = (isDragging) => ({
  userSelect: "none",
  width: "100%",
  background: isDragging ? "#5182BB" : "lightgrey",
  marginBottom: "1em",
  padding: "1em",
  fontSize: "1.2em"
});

const getListStyle = () => ({
  paddingTop: "1em",
  background: "#FAFAFA"
});

/**
 * The report generation pop up window
 */
const ReportGenerationModal = observer(({ visible, onCancel }) => {
  const [reportName, setReportName] = useState("");

  const {
    reportGenerationStore: { numberOfComponents, components, generatingReport, generateReport, swapComponents }
  } = useStores();

  // Handle generating the report after clicking the Generate button
  const onOk = () => {
    if (reportName.length === 0) {
      message.error("You have to enter a report name!").then();
      return;
    }

    if (numberOfComponents === 0) {
      message.error("You cannot create a document without a selected section!").then();
      return;
    }

    try {
      generateReport(reportName).then();
    } catch (e) {
      message.error(e.message).then();
    }
  };

  // Handle rearranging the order of each sections in the report
  const onDragEnd = ({ source, destination }) => {
    if (source && destination) {
      swapComponents(source.index, destination.index);
    }
  };

  const renderComponets = (item, index) => {
    if(item.type !== "Table" || item.tableShow === true) {
    return (<Draggable key={item.key} draggableId={item.key} index={index}>
      {(provided, snapshot) => (
        <Row {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
          <div style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>{item.key}</div>
        </Row>
      )}
    </Draggable>)}
  }

  return (
    <Modal
      visible={visible}
      title="Report Structure"
      closable={false}
      onOk={onOk}
      onCancel={onCancel}
      okText="Generate"
    >
      <Form>
        <Form.Item label="Name">
          <Input value={reportName} onChange={(e) => setReportName(e.target.value)} />
        </Form.Item>
      </Form>
      {numberOfComponents > 0 && (
        <Spin tip="Generating report ..." spinning={generatingReport}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <Col {...provided.droppableProps} ref={provided.innerRef} style={getListStyle()}>
                  {components.map((item, index) => (
                    renderComponets(item, index)
                  ))}
                  {provided.placeholder}
                </Col>
              )}
            </Droppable>
          </DragDropContext>
        </Spin>
      )}
      {numberOfComponents === 0 && <Text>No component is selected!</Text>}
    </Modal>
  );
});
export default ReportGenerationModal;
