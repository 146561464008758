import { Modal, Table, Tag } from "antd";
import React, { useState } from "react";
import { useBoolean } from "usehooks-ts";
import { useTopNlSearches } from "../../../hooks/StatisticsHooks";
import { MonthlyQueriesByNlSearchVisualization } from "../MonthlyQueriesByUserNlSearch/MonthlyQueriesByNlSearchVisualization";

/**
 * To draw the top NL searches table
 */
export const TopNlSearchesTable = ({ startDate, endDate }) => {
  const { data, loading } = useTopNlSearches();
  const [selectedNlSearch, setSelectedNlSearch] = useState(null);
  const { value: modalVisible, setTrue: showModal, setFalse: hideModal } = useBoolean();

  const onNlSearchClick = (nlSearch) => {
    setSelectedNlSearch(nlSearch);
    showModal();
  };

  return (
    <>
      <Table dataSource={data} rowKey="nlSearch" loading={loading}>
        <Table.Column
          title="Natural Language Search"
          width="40%"
          dataIndex="nlSearch"
          render={(nlSearch) => (
            <Tag style={{ cursor: "pointer" }} onClick={() => onNlSearchClick(nlSearch)}>
              {nlSearch}
            </Tag>
          )}
        />
        <Table.Column title="Number of times" dataIndex="numberOfTimes" width="30%" />
        <Table.Column title="Percentage" dataIndex="percentage" width="30%" />
      </Table>
      {modalVisible && (
        <Modal visible={modalVisible} width={"100%"} footer={null} onCancel={hideModal}>
          <MonthlyQueriesByNlSearchVisualization nlSearch={selectedNlSearch} startDate={startDate} endDate={endDate} />
        </Modal>
      )}
    </>
  );
};
