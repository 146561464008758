import React from "react";
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { useMonthlyQueriesByNlSearch } from "../../../hooks/StatisticsHooks";

/**
 * To draw the monthly queries by NL searches bar chart
 */
export const MonthlyQueriesByNlSearchVisualization = ({ nlSearch, startDate, endDate }) => {
  const { data } = useMonthlyQueriesByNlSearch(nlSearch, startDate, endDate);

  return (
    <ResponsiveContainer width="100%" height={635} margin={{ right: 50 }}>
      <BarChart data={data} margin={{ top: 20, right: 50 }}>
        <Legend verticalAlign="top" height={50} iconSize={30} />
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="mmYYYY" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="numberOfTimes" fill="#5182BB" name="Number of times" />
      </BarChart>
    </ResponsiveContainer>
  );
};
