import React, { useState } from "react";
import { Modal } from "antd";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  YAxis,
  XAxis
} from "recharts";
import { VisualizationType } from "../../../consts/VisualizationType";
import { useQueriesBreakdown } from "../../../hooks/StatisticsHooks";
import { NumberOfFaultCodeOrNLQueriesByMonthVisualization } from "../NumberOfFaultCodeOrNLQueriesByMonthVisualization/NumberOfFaultCodeOrNLQueriesByMonthVisualization";
import { useBoolean } from "usehooks-ts";

/**
 * To draw queries breakdown bar/pie charts
 */
export const QueriesBreakdownVisualization = ({ visualizationType, visualizationRef, startDate, endDate }) => {
  const { data } = useQueriesBreakdown();
  const { value: modalVisible, setTrue: showModal, setFalse: hideModal } = useBoolean();
  const [modalIndex, setModalIndex] = useState();
  const colours = ["#1f77b4", "#ff7f0e"];

  const onBarChartClick = (index) => {
    setModalIndex(index);
    showModal();
  };

  let chart;
  switch (visualizationType) {
    case VisualizationType.PIE:
      chart = (
        <PieChart ref={visualizationRef} margin={{  top: 20 }}>
          <Legend verticalAlign="top" height={50} iconSize={30} />
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={renderCustomizedLabel}
            fill="#8884d8"
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={colours[index % colours.length]} onClick={() => onBarChartClick(index)} />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      );
      break;
    case VisualizationType.BAR:
    default:
      chart = (
        <BarChart
          ref={visualizationRef}
          width={500}
          height={300}
          data={data}
          margin={{ top: 20, left: 20, right: 50 }}
        >
          <Legend verticalAlign="top" height={50} iconSize={30} />
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="value" fill="#8884d8" name="Number of Times">
            {data?.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={colours[index % 20]} onClick={() => onBarChartClick(index)} />
            ))}
          </Bar>
        </BarChart>
      );
      break;
  }

  return (
    <div style={{ backgroundColor: "white" }}>
      <ResponsiveContainer width="100%" height={635}>
        {chart}
      </ResponsiveContainer>
      <Modal visible={modalVisible} width={"100%"} footer={null} onCancel={hideModal}>
        <NumberOfFaultCodeOrNLQueriesByMonthVisualization startDate={startDate} endDate={endDate} index={modalIndex} />
      </Modal>
    </div>
  );
};

// To calculate the position of where to put the label
const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? "start" : "end"} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};