import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Layout,
  message,
  notification,
  Row,
  Space,
  Typography,
  Upload,
  Image
} from "antd";
import ImgCrop from "antd-img-crop";
import React, { useEffect, useState } from "react";
import { useBoolean } from "usehooks-ts";
import { useHandleUpdateProfile } from "../../../hooks/AuthHooks";
import { useUser } from "../../../hooks/UserHooks";
import { convertBytesToMegabytes } from "../../../utils/FileUtils";
import ChangePasswordModal from "../../organisms/ChangePasswordForm/ChangePasswordModal";
import SideNavigationMenu from "../../organisms/SideNavigationMenu/SideNavigationMenu";
import TopMenuNavigation from "../../organisms/TopMenuNavigation/TopMenuNavigation";
import { useProfilePageStyles } from "./styles";
import KeycloakUserService from "../../../services/KeycloakUserService";
import { useHandleUpdateAvatar } from "../../../hooks/UserHooks";
import { useHandleUpdateUserAttribute } from "../../../hooks/AuthHooks";

const { Content } = Layout;
const { Text } = Typography;
const { REACT_APP_AZURE_BLOB_STORAGE_PROFILE_PHOTO_URL, REACT_APP_USER_PROFILE_PHOTO_URL_KEYCLOAK_ATTRIBUTE_NAME } =
  process.env;

/**
 * The user profile page
 */
const ProfilePage = () => {
  const userID = KeycloakUserService.getUserID();
  const { data } = useUser(userID);
  const [handleUpdateProfile] = useHandleUpdateProfile();
  const [form] = Form.useForm();
  const [handleUpdateAvatar] = useHandleUpdateAvatar();
  const [profilePhotoUrl, setProfilePhotoUrl] = useState("");
  const [handleUpdateUserAttribute] = useHandleUpdateUserAttribute();

  // Get the user profile photo and display it
  useEffect(() => {
    form.setFieldsValue(data);
    const profilePhotoUrlFromKeycloak = KeycloakUserService.getProfilePhotoUrl();
    setProfilePhotoUrl(profilePhotoUrlFromKeycloak);
  }, [form, data]);

  const {
    value: changePasswordModalVisibible,
    setTrue: showChangePasswordModal,
    setFalse: hideChangePasswordModal
  } = useBoolean(false);

  // Handle update user firstname and lastname
  const onFinish = ({ firstName, lastName }) => {
    handleUpdateProfile(
      { firstName, lastName },
      () => {
        notification.success({
          message: "Updated profile successfully!",
          placement: "topRight"
        });
      },
      (errorMessages) => {
        errorMessages.forEach((errorMessage) => {
          message.error(errorMessage).then();
        });
      }
    );
  };

  const { mainContentCls, titleCls } = useProfilePageStyles();

  const customRequest = (option) => {
    const reader = new FileReader();
    reader.readAsDataURL(option.file);
    reader.onloadend = function (e) {
      if (e && e.target && e.target.result) {
        option.onSuccess();
      }
    };
  };

  // Check the profile image type
  const beforeUpload = async ({ type, size }) => {
    const isJpgOrPng = type === "image/jpeg" || type === "image/png";
    if (!isJpgOrPng) {
      await message.error("You can only upload JPG/PNG file!", 2);
    }
    const isLt2M = convertBytesToMegabytes(size) < 2;
    if (!isLt2M) {
      await message.error("Image must smaller than 2MB!", 2);
    }
    return isJpgOrPng && isLt2M;
  };

  // To handle the profile photo upload
  const onAvatarChange = ({ file: { status, originFileObj, type, name } }) => {
    if (status === "done") {
      // Call the upload profile photo hook
      handleUpdateAvatar(
        originFileObj,
        userID,
        (profileUrl) => {
          // Update the keycloak user attribute
          handleUpdateUserAttribute(REACT_APP_USER_PROFILE_PHOTO_URL_KEYCLOAK_ATTRIBUTE_NAME, profileUrl);

          // Set the Profile Photo Url
          setProfilePhotoUrl(profileUrl);

          // Refresh the page
          window.location.reload(false);
        },
        (errorMessages) => {
          errorMessages.forEach((errorMessage) => {
            message.error(errorMessage).then();
          });
        }
      );
    }
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <TopMenuNavigation />
      <Layout>
        <SideNavigationMenu />
        <Content className={mainContentCls}>
          <Form form={form} name="control-ref" onFinish={onFinish} layout="vertical">
            <Row align="middle" justify="space-between">
              <Text className={titleCls}>My Profile</Text>
              <Space direction="horizontal">
                <Button id="change_password_button" type="default" onClick={showChangePasswordModal}>
                  Change password
                </Button>
                <Button id="save_details_button" type="primary" htmlType="submit">
                  Save changes
                </Button>
              </Space>
            </Row>
            <Divider />
            <Col style={{ width: "500px" }}>
              <Form.Item>
                <ImgCrop rotate>
                  <Upload
                    style={{ width: "50px !important" }}
                    name="avatar"
                    listType="picture-card"
                    showUploadList={false}
                    customRequest={customRequest}
                    beforeUpload={beforeUpload}
                    onChange={onAvatarChange}
                  >
                    {profilePhotoUrl ? (
                      <img
                        src={profilePhotoUrl}
                        alt="avatar"
                        style={{ width: "100%", borderRadius: "150px" }}
                        crossOrigin=""
                      />
                    ) : (
                      <Space>
                        <PlusOutlined />
                        <Text>Upload</Text>
                      </Space>
                    )}
                  </Upload>
                </ImgCrop>
              </Form.Item>
              <Form.Item
                name="firstName"
                label="First Name"
                rules={[{ required: true, message: "✕ Please input your first name!" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="lastName"
                label="Last Name"
                rules={[{ required: true, message: "✕ Please input your last name!" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item name="email" label="Email address">
                <Input disabled />
              </Form.Item>
              <Form.Item name="userRoles" label="Roles">
                <Input disabled />
              </Form.Item>
            </Col>
          </Form>
        </Content>
      </Layout>
      <ChangePasswordModal visible={changePasswordModalVisibible} onCancel={hideChangePasswordModal} />
    </Layout>
  );
};

export default ProfilePage;
