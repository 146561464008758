import { Modal, Table, Tag } from "antd";
import React, { useState } from "react";
import { useBoolean } from "usehooks-ts";
import { useTopUsers } from "../../../hooks/StatisticsHooks";
import { MonthlyQueriesByUsernameVisualization } from "../MonthlyQueriesByUserVisualization/MonthlyQueriesByUsernameVisualization";

/**
 * To draw the queries per user table
 */
export const QueriesPerUserTable = ({ startDate, endDate }) => {
  const { data, loading } = useTopUsers();
  const [selectedUsername, setSelectedUsername] = useState(null);
  const { value: modalVisible, setTrue: showModal, setFalse: hideModal } = useBoolean();

  const onUsernameTagClick = (username) => {
    setSelectedUsername(username);
    showModal();
  };

  return (
    <>
      <Table dataSource={data} rowKey="username" loading={loading}>
        <Table.Column
          title="Username"
          width="40%"
          dataIndex="username"
          render={(username) => {
            return (
              <Tag style={{ cursor: "pointer" }} onClick={() => onUsernameTagClick(username)}>
                {username}
              </Tag>
            );
          }}
        />
        <Table.Column title="Number of queries" dataIndex="numberOfQueries" width="30%" />
        <Table.Column title="Percentage" dataIndex="percentage" width="30%" />
      </Table>
      {modalVisible && (
        <Modal visible={modalVisible} width={"100%"} footer={null} onCancel={hideModal}>
          <MonthlyQueriesByUsernameVisualization username={selectedUsername} startDate={startDate} endDate={endDate} />
        </Modal>
      )}
    </>
  );
};
