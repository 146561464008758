import React, { useState } from "react";
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { useNumberOfFaultCodeQueriesByMonth } from "../../../hooks/StatisticsHooks";
import { useNumberOfNLQueriesByMonth } from "../../../hooks/StatisticsHooks";

/**
 * To draw number of fault code or number of NL queries by month bar chart
 */
export const NumberOfFaultCodeOrNLQueriesByMonthVisualization = ({ startDate, endDate, index }) => {
  const { selectInDateRange: selectInDateRangeForNumberOfNLQueriesByMonth } = useNumberOfNLQueriesByMonth();
  const { selectInDateRange: selectInDateRangeForNumberOfFaultCodeQueriesByMonth } =
    useNumberOfFaultCodeQueriesByMonth();

  // Retrieve the data
  selectInDateRangeForNumberOfNLQueriesByMonth(startDate, endDate);
  selectInDateRangeForNumberOfFaultCodeQueriesByMonth(startDate, endDate);
  const { data: numberOfNLQueriesByMonthData } = useNumberOfNLQueriesByMonth(startDate, endDate);
  const { data: numberOfFaultCodeQueriesByMonthData } = useNumberOfFaultCodeQueriesByMonth(startDate, endDate);

  return (
    <ResponsiveContainer width="100%" height={600} margin={{ right: 50 }}>
      <BarChart
        data={index == 0 ? numberOfNLQueriesByMonthData : numberOfFaultCodeQueriesByMonthData}
        margin={{ top: 20, right: 50 }}
      >
        <Legend verticalAlign="top" height={50} iconSize={30} />
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="mmYYYY" name="mmYYYY" />
        <YAxis dataKey="numberOfTimes" name="numberOfTimes" />
        <Tooltip />
        <Legend />
        <Bar dataKey="numberOfTimes" fill="#5182BB" name="Number of times" />
      </BarChart>
    </ResponsiveContainer>
  );
};
