import { Divider } from "antd";
import React, { useEffect, useState } from "react";
import { useCurrentPng } from "recharts-to-png";
import { useBoolean } from "usehooks-ts";
import { VisualizationType } from "../../../consts/VisualizationType";
import { useMonthlyQueries } from "../../../hooks/StatisticsHooks";
import { useStores } from "../../../stores";
import { HelperToolbar } from "../../molecules/HelperToolbar/HelperToolbar";
import { MonthlyQueriesVisualization } from "../../molecules/MonthlyQueriesVisualization/MonthlyQueriesVisualization";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';

/**
 * The monthly queries section
 */
export const MonthlyQueriesSection = () => {
  const {
    reportGenerationStore: { addComponent }
  } = useStores();
  const { data } = useMonthlyQueries();
  const { selectInDateRange } = useMonthlyQueries();
  const [visualizationType, setVisualizationType] = useState(VisualizationType.BAR);
  const [getPng, { ref }] = useCurrentPng();

  // Add the monthly queries chart image and table to the local storage
  useEffect(() => {
    const rows = [];
    console.log(data);
    if (data) {
      data?.forEach(({ month , year, numberOfQueries}) => {
        rows.push([toMonthName(month), year, numberOfQueries]);
      });
    }
    addComponent({ type: "Photo", key: "Monthly Queries", group:'monthlyQueries', getPng: getPng });
    addComponent({
      type: "Table",
      key: "Monthly Queries Table",
      group: "monthlyQueries",
      columns: [ "Month", "Year", "Number of Queries"],
      rows: rows,
      tableShow:false
    });
  }, [data]);

  // Handle date range changes
  const onDateRangeChange = (start, end) => {
    selectInDateRange(start, end);
  };

  // Convert Date to month name
  const toMonthName = (monthNumber) => {
    const date = new Date();
    date.setMonth(monthNumber - 1);
  
    return date.toLocaleString('en-US', {
      month: 'long',
    });
  }

  // Handle changing the chart type to display
  const onVisualizationTypeSelect = (type) => {
    setVisualizationType(type);
  };

  const { value: modalVisible, setTrue: showModal, setFalse: hideModal } = useBoolean();

  return (
    <>
      <HelperToolbar
        title="Monthly Queries"
        visualizationTypeOptions={[VisualizationType.LINE, VisualizationType.BAR]}
        onDateRangeChange={onDateRangeChange}
        onVisualizationTypeSelect={onVisualizationTypeSelect}
        onFullscreenIconClick={showModal}
      />
      <Divider />
      <MonthlyQueriesVisualization visualizationType={visualizationType} visualizationRef={ref} />
      {modalVisible && (
        <Modal
        open={modalVisible}
        onClose={hideModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={{ transform:'scale(0.78)'}}>
        <Box sx={{position: 'absolute', top:'64vh',left: '50%', transform: 'translate(-50%, -50%)', width: '80%',bgcolor: 'background.paper',border: '2px solid #000',boxShadow: 24,p: 4,}}>
        <MonthlyQueriesVisualization
         visualizationType={visualizationType} />
         <Stack spacing={2} direction="row" sx={{float:'right' ,paddingY:'10px'}}>
         <div style={{paddingTop:'30px'}}>
          <Button variant="contained" style={{marginRight:'5px'}} onClick={hideModal}>OK</Button>
         </div>
       </Stack>
        </Box>
        </div>
      </Modal>
      )}
    </>
  );
};