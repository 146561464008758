import { gql } from "graphql-request";
import { getGraphQLClientInstance } from "../clients/clientFactory";
// import KeycloakUserService from "./KeycloakUserService";

/**
 * StatisticsService graplql endpoints
 */
export class StatisticsService {
  /**
   * To call the get top users endpoint
   */
  async getTopUsers(start = "", end = "") {
    const groupId = localStorage.getItem('projectGroup');
    const query = gql`
      query ($dateRange: DateRange! 
              $groupId: String!) {
        topUsers(dateRange: $dateRange,
          groupId: $groupId) {
          username
          numberOfQueries
          percentage
        }
      }
    `;

    const { topUsers } = await getGraphQLClientInstance({ secured: true }).request(query, {
      dateRange: {
        start: start && start.length > 0 ? start : null,
        end: end && end.length > 0 ? end : null
      },
      groupId: groupId
    });

    return topUsers;
  }

  /**
   * To call the get monthly queries endpoint
   */
  async getMonthlyQueries(start = "", end = "") {
    const groupId = localStorage.getItem('projectGroup');
    const query = gql`
      query ($dateRange: DateRange! 
            $groupId: String!) {
        monthlyQueries(dateRange: $dateRange, groupId: $groupId) {
          month
          year
          mmYYYY
          numberOfQueries
        }
      }
    `;

    const { monthlyQueries } = await getGraphQLClientInstance({ secured: true }).request(query, {
      dateRange: {
        start: start && start.length > 0 ? start : null,
        end: end && end.length > 0 ? end : null
      },
      groupId: groupId
    });

    return monthlyQueries;
  }

  /**
   *
   * To call the get monthly queries by username endpoint
   */
  async getMonthlyQueriesByUsername(username: string, startDate: string, endDate: string) {
    const groupId = localStorage.getItem('projectGroup');
    const query = gql`
      query ($username: String!, $dateRange: DateRange!, $groupId: String! ) {
        monthlyQueriesByUsername(username: $username, dateRange: $dateRange, groupId: $groupId) {
          month
          year
          mmYYYY
          numberOfTimes
        }
      }
    `;

    const { monthlyQueriesByUsername } = await getGraphQLClientInstance({ secured: true }).request(query, {
      username: username,
      dateRange: {
        start: startDate && startDate.length > 0 ? startDate : null,
        end: endDate && endDate.length > 0 ? endDate : null
      },
      groupId: groupId
    });

    return monthlyQueriesByUsername;
  }

  /**
   * To call the get queries breakdown endpoint
   */
  async getQueriesBreakdown(start = "", end = "") {
    const groupId = localStorage.getItem('projectGroup');
    const query = gql`
      query ($dateRange: DateRange! 
              $groupId: String!) {
        queriesBreakdown(dateRange: $dateRange, groupId: $groupId) {
          name
          value
        }
      }
    `;

    const { queriesBreakdown } = await getGraphQLClientInstance({ secured: true }).request(query, {
      dateRange: {
        start: start && start.length > 0 ? start : null,
        end: end && end.length > 0 ? end : null
      },
      groupId: groupId
    });

    return queriesBreakdown;
  }

  /**
   * To call the get top fault codes endpoint
   */
  async getTopFaultCodes(start = "", end = "") {
    const groupId = localStorage.getItem('projectGroup');
    const query = gql`
      query ($dateRange: DateRange!
              $groupId: String!) {
        topFaultCodes(dateRange: $dateRange, groupId: $groupId) {
          code
          numberOfTimes
          percentage
        }
      }
    `;

    const { topFaultCodes } = await getGraphQLClientInstance({ secured: true }).request(query, {
      dateRange: {
        start: start && start.length > 0 ? start : null,
        end: end && end.length > 0 ? end : null
      },
      groupId: groupId
    });

    return topFaultCodes;
  }

  /**
   * To call the get monthly queries by fault code endpoint
   */
  async getMonthlyQueriesByFaultCode(faultCode: number, start: string, end: string) {
    const groupId = localStorage.getItem('projectGroup');
    const query = gql`
      query ($faultCode: Float!, $dateRange: DateRange!, $groupId: String!) {
        monthlyQueriesByFaultCode(faultCode: $faultCode, dateRange: $dateRange, groupId: $groupId) {
          month
          year
          mmYYYY
          numberOfTimes
        }
      }
    `;

    const { monthlyQueriesByFaultCode } = await getGraphQLClientInstance({ secured: true }).request(query, {
      faultCode: faultCode,
      dateRange: {
        start: start && start.length > 0 ? start : null,
        end: end && end.length > 0 ? end : null
      },
      groupId: groupId
    });

    return monthlyQueriesByFaultCode;
  }

  /**
   * To call the get top NL searches endpoint
   */
  async getTopNlSearches(start = "", end = "") {
    const groupId = localStorage.getItem('projectGroup');
    const query = gql`
      query ($dateRange: DateRange!
              $groupId: String!) {
        topNlSearches(dateRange: $dateRange, groupId: $groupId) {
          nlSearch
          numberOfTimes
          percentage
        }
      }
    `;

    const { topNlSearches } = await getGraphQLClientInstance({ secured: true }).request(query, {
      dateRange: {
        start: start && start.length > 0 ? start : null,
        end: end && end.length > 0 ? end : null
      },
      groupId: groupId
    });

    return topNlSearches;
  }

  /**
   * To call the get monthly queries by NL search endpoint
   */
  async getMonthlyQueriesByNlSearch(nlSearch: string, startDate: string, endDate: string) {
    const groupId = localStorage.getItem('projectGroup');
    const query = gql`
      query ($nlSearch: String!, $dateRange: DateRange!, $groupId: String!) {
        monthlyQueriesByNlSearch(nlSearch: $nlSearch, dateRange: $dateRange, groupId: $groupId) {
          month
          year
          mmYYYY
          numberOfTimes
        }
      }
    `;

    const { monthlyQueriesByNlSearch } = await getGraphQLClientInstance({ secured: true }).request(query, {
      nlSearch: nlSearch,
      dateRange: {
        start: startDate && startDate.length > 0 ? startDate : null,
        end: endDate && endDate.length > 0 ? endDate : null
      },
      groupId: groupId
    });

    return monthlyQueriesByNlSearch;
  }

  /**
   * To call the get number of NL queries by month endpoint
   */
  async getNumberOfNLQueriesByMonth(startDate: string, endDate: string) {
    const groupId = localStorage.getItem('projectGroup');
    const query = gql`
      query ($dateRange: DateRange!
              $groupId: String!) {
        numberOfNLQueriesByMonth(dateRange: $dateRange, groupId: $groupId) {
          mmYYYY
          numberOfTimes
        }
      }
    `;

    const { numberOfNLQueriesByMonth } = await getGraphQLClientInstance({ secured: true }).request(query, {
      dateRange: {
        start: startDate && startDate.length > 0 ? startDate : null,
        end: endDate && endDate.length > 0 ? endDate : null
      },
      groupId: groupId
    });

    return numberOfNLQueriesByMonth;
  }

  /**
   * To call the get number of fault code queries by month endpoint
   */
  async getNumberOfFaultCodeQueriesByMonth(startDate: string, endDate: string) {
    const groupId = localStorage.getItem('projectGroup');
    const query = gql`
      query ($dateRange: DateRange!
              $groupId: String!) {
        numberOfFaultCodeQueriesByMonth(dateRange: $dateRange, groupId: $groupId) {
          mmYYYY
          numberOfTimes
        }
      }
    `;

    const { numberOfFaultCodeQueriesByMonth } = await getGraphQLClientInstance({ secured: true }).request(query, {
      dateRange: {
        start: startDate && startDate.length > 0 ? startDate : null,
        end: endDate && endDate.length > 0 ? endDate : null
      },
      groupId: groupId
    });

    return numberOfFaultCodeQueriesByMonth;
  }

  /**
   * To call the get information lookups breakdown endpoint
   */
  async getInformationLookupsBreakdown(start = "", end = "") {
    const groupId = localStorage.getItem('projectGroup');
    const query = gql`
      query ($dateRange: DateRange!
            $groupId: String!) {
        informationLookupsBreakdown(dateRange: $dateRange, groupId: $groupId) {
          name
          value
        }
      }
    `;

    const { informationLookupsBreakdown } = await getGraphQLClientInstance({ secured: true }).request(query, {
      dateRange: {
        start: start && start.length > 0 ? start : null,
        end: end && end.length > 0 ? end : null
      },
      groupId: groupId
    });

    return informationLookupsBreakdown;
  }

  /**
   * To call the get number of procedures by month endpoint
   */
  async getNumberOfProceduresByMonth(startDate: string, endDate: string) {
    const groupId = localStorage.getItem('projectGroup');
    const query = gql`
      query ($dateRange: DateRange!
              $groupId: String!) {
        numberOfProceduresByMonth(dateRange: $dateRange, groupId: $groupId) {
          mmYYYY
          numberOfTimes
        }
      }
    `;

    const { numberOfProceduresByMonth } = await getGraphQLClientInstance({ secured: true }).request(query, {
      dateRange: {
        start: startDate && startDate.length > 0 ? startDate : null,
        end: endDate && endDate.length > 0 ? endDate : null
      },
      groupId: groupId
    });

    return numberOfProceduresByMonth;
  }

  /**
   * To call the get number of parts breakdown by month endpoint
   */
  async getNumberOfPartsBreakdownByMonth(startDate: string, endDate: string) {
    const groupId = localStorage.getItem('projectGroup');
    const query = gql`
      query ($dateRange: DateRange!
            $groupId: String!) {
        numberOfPartsBreakdownByMonth(dateRange: $dateRange, groupId: $groupId) {
          mmYYYY
          numberOfTimes
        }
      }
    `;

    const { numberOfPartsBreakdownByMonth } = await getGraphQLClientInstance({ secured: true }).request(query, {
      dateRange: {
        start: startDate && startDate.length > 0 ? startDate : null,
        end: endDate && endDate.length > 0 ? endDate : null
      },
      groupId: groupId
    });

    return numberOfPartsBreakdownByMonth;
  }

  /**
   * To call the get number of work order by month endpoint
   */
  async getNumberOfWorkOrderByMonth(startDate: string, endDate: string) {
    const groupId = localStorage.getItem('projectGroup');
    const query = gql`
      query ($dateRange: DateRange!
            $groupId: String!) {
        numberOfWorkOrderByMonth(dateRange: $dateRange, groupId: $groupId) {
          mmYYYY
          numberOfTimes
        }
      }
    `;

    const { numberOfWorkOrderByMonth } = await getGraphQLClientInstance({ secured: true }).request(query, {
      dateRange: {
        start: startDate && startDate.length > 0 ? startDate : null,
        end: endDate && endDate.length > 0 ? endDate : null
      },
      groupId: groupId
    });

    return numberOfWorkOrderByMonth;
  }
}

export const statisticsService = new StatisticsService();
