import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { createStores, StoreContext } from "./stores";
import KeycloakUserService from "./services/KeycloakUserService";

const renderApp = () =>
  createStores().then((stores) => {
    ReactDOM.render(
      <StoreContext.Provider value={stores}>
        <App />
      </StoreContext.Provider>,
      document.getElementById("root")
    );
  });

reportWebVitals();

// Secure the app using Keycloak
KeycloakUserService.initKeycloak(renderApp);

