import { useState } from "react";
import useSWR from "swr";
import useSWRImmutable from "swr/immutable";
import { statisticsService } from "../services/StatisticsService";

/**
 * Handle get top users statistics data
 */
export const useTopUsers = () => {
  const { data, error, mutate } = useSWRImmutable("TopUsers", () =>
    statisticsService.getTopUsers().then((result) => result)
  );
  const [selecting, setSelecting] = useState(false);

  const selectInDateRange = (start, end) => {
    setSelecting(true);
    mutate(async () => {
      return await statisticsService.getTopUsers(start, end);
    }, false).then(() => {
      setSelecting(false);
    });
  };

  return { data, error, loading: !data && !error, selecting, selectInDateRange };
};

/**
 * Handle get monthly queries statistics data
 */
export const useMonthlyQueries = () => {
  const { data, error, mutate } = useSWRImmutable("MonthlyQueries", () =>
    statisticsService.getMonthlyQueries().then((result) => result)
  );
  const [selecting, setSelecting] = useState(false);

  const selectInDateRange = (start, end) => {
    setSelecting(true);
    mutate(async () => {
      return await statisticsService.getMonthlyQueries(start, end);
    }, false).then(() => {
      setSelecting(false);
    });
  };

  return { data, error, loading: !data && !error, selecting, selectInDateRange };
};

/**
 * Handle get monthly queries by username statistics data
 */
export const useMonthlyQueriesByUsername = (username, startDate, endDate) => {
  const { data, error } = useSWR(
    `MonthlyQueriesByUsername-${username}`,
    () => statisticsService.getMonthlyQueriesByUsername(username, startDate, endDate).then((result) => result),
    { revalidateOnFocus: false }
  );

  return { data, error, loading: !data && !error };
};

/**
 * Handle get queries breakdown statistics data
 */
export const useQueriesBreakdown = () => {
  const { data, error, mutate } = useSWRImmutable("QueriesBreakdown", () =>
    statisticsService.getQueriesBreakdown().then((result) => result)
  );
  const [selecting, setSelecting] = useState(false);

  const selectInDateRange = (start, end) => {
    setSelecting(true);
    mutate(async () => {
      return await statisticsService.getQueriesBreakdown(start, end);
    }, false).then(() => {
      setSelecting(false);
    });
  };

  return { data, error, loading: !data && !error, selecting, selectInDateRange };
};

/**
 * Handle get top fault codes statistics data
 */
export const useTopFaultCodes = () => {
  const { data, error, mutate } = useSWRImmutable("TopFaultCodes", () =>
    statisticsService.getTopFaultCodes().then((result) => result)
  );
  const [selecting, setSelecting] = useState(false);

  const selectInDateRange = (start, end) => {
    setSelecting(true);
    mutate(async () => {
      return await statisticsService.getTopFaultCodes(start, end);
    }, false).then(() => {
      setSelecting(false);
    });
  };

  return { data, error, loading: !data && !error, selecting, selectInDateRange };
};

/**
 * Handle get monthly queries by fault code statistics data
 */
export const useMonthlyQueriesByFaultCode = (faultCode, startDate, endDate) => {
  const { data, error } = useSWR(
    `MonthlyQueriesByFaultCode-${faultCode}`,
    () => statisticsService.getMonthlyQueriesByFaultCode(faultCode, startDate, endDate).then((result) => result),
    { revalidateOnFocus: false }
  );

  return { data, error, loading: !data && !error };
};

/**
 * Handle get top NL searches statistics data
 */
export const useTopNlSearches = () => {
  const { data, error, mutate } = useSWRImmutable("TopNlSearches", () =>
    statisticsService.getTopNlSearches().then((result) => result)
  );
  const [selecting, setSelecting] = useState(false);

  const selectInDateRange = (start, end) => {
    setSelecting(true);
    mutate(async () => {
      return await statisticsService.getTopNlSearches(start, end);
    }, false).then(() => {
      setSelecting(false);
    });
  };

  return { data, error, loading: !data && !error, selecting, selectInDateRange };
};

/**
 * Handle get monthly queries by NL searches statistics data
 */
export const useMonthlyQueriesByNlSearch = (nlSearch, startDate, endDate) => {
  const { data, error } = useSWR(
    `MonthlyQueriesByNlSearch-${nlSearch}`,
    () => statisticsService.getMonthlyQueriesByNlSearch(nlSearch, startDate, endDate).then((result) => result),
    { revalidateOnFocus: false }
  );

  return { data, error, loading: !data && !error };
};

/**
 * Handle get number of NL queries by month statistics data
 */
export const useNumberOfNLQueriesByMonth = (startDate, endDate) => {
  const { data, error, mutate } = useSWR(
    "NumberOfNLQueriesByMonth",
    () => statisticsService.getNumberOfNLQueriesByMonth(startDate, endDate).then((result) => result),
    { revalidateOnFocus: false }
  );

  const selectInDateRange = (startDate, endDate) => {
    mutate(async () => {
      return await statisticsService.getNumberOfNLQueriesByMonth(startDate, endDate);
    }, false).then(() => {});
  };

  return { data, error, loading: !data && !error, selectInDateRange };
};

/**
 * Handle get number of fault code queries by month statistics data
 */
export const useNumberOfFaultCodeQueriesByMonth = (startDate, endDate) => {
  const { data, error, mutate } = useSWR(
    "NumberOfFaultCodeQueriesByMonth",
    () => statisticsService.getNumberOfFaultCodeQueriesByMonth(startDate, endDate).then((result) => result),
    { revalidateOnFocus: false }
  );

  const selectInDateRange = (startDate, endDate) => {
    mutate(async () => {
      return await statisticsService.getNumberOfFaultCodeQueriesByMonth(startDate, endDate);
    }, false).then(() => {});
  };

  return { data, error, loading: !data && !error, selectInDateRange };
};

/**
 * Handle get information lookups breakdown statisitics data
 */
export const useInformationLookupsBreakdown = () => {
  const { data, error, mutate } = useSWRImmutable("InformationLookupsBreakdown", () =>
    statisticsService.getInformationLookupsBreakdown().then((result) => result)
  );
  const [selecting, setSelecting] = useState(false);

  const selectInDateRange = (start, end) => {
    setSelecting(true);
    mutate(async () => {
      return await statisticsService.getInformationLookupsBreakdown(start, end);
    }, false).then(() => {
      setSelecting(false);
    });
  };

  return { data, error, loading: !data && !error, selecting, selectInDateRange };
};

/**
 * Handle get number of procedures by month statistics data
 */
export const useNumberOfProceduresByMonth = () => {
  const { data, error, mutate } = useSWR(
    "NumberOfProceduresByMonth",
    () => statisticsService.getNumberOfProceduresByMonth(startDate, endDate).then((result) => result),
    { revalidateOnFocus: false }
  );

  const selectInDateRange = (startDate, endDate) => {
    mutate(async () => {
      return await statisticsService.getNumberOfProceduresByMonth(startDate, endDate);
    }, false).then(() => {});
  };

  return { data, error, loading: !data && !error, selectInDateRange };
};

/**
 * Handle get number of parts breakdown by month statistics data
 */
export const useNumberOfPartsBreakdownByMonth = () => {
  const { data, error, mutate } = useSWR(
    "NumberOfPartsBreakdownByMonth",
    () => statisticsService.getNumberOfPartsBreakdownByMonth(startDate, endDate).then((result) => result),
    { revalidateOnFocus: false }
  );

  const selectInDateRange = (startDate, endDate) => {
    mutate(async () => {
      return await statisticsService.getNumberOfPartsBreakdownByMonth(startDate, endDate);
    }, false).then(() => {});
  };

  return { data, error, loading: !data && !error, selectInDateRange };
};

/**
 * Handle get number of work order by month statistics data
 */
export const useNumberOfWorkOrderByMonth = () => {
  const { data, error, mutate } = useSWR(
    "NumberOfWorkOrderByMonth",
    () => statisticsService.getNumberOfWorkOrderByMonth(startDate, endDate).then((result) => result),
    { revalidateOnFocus: false }
  );

  const selectInDateRange = (startDate, endDate) => {
    mutate(async () => {
      return await statisticsService.getNumberOfWorkOrderByMonth(startDate, endDate);
    }, false).then(() => {});
  };

  return { data, error, loading: !data && !error, selectInDateRange };
};
