import React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import { VisualizationType } from "../../../consts/VisualizationType";
import { useMonthlyQueries } from "../../../hooks/StatisticsHooks";

/**
 * To draw the monthly queries bar/line charts
 */
export const MonthlyQueriesVisualization = ({ visualizationType, visualizationRef }) => {
  const { data } = useMonthlyQueries();

  let chart;
  switch (visualizationType) {
    case VisualizationType.BAR:
      chart = (
        <BarChart data={data} ref={visualizationRef} margin={{ top: 20, right: 50 }}>
          <Legend verticalAlign="top" height={50} iconSize={30} />
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="mmYYYY" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="numberOfQueries" fill="#5182BB" name="Number of queries" />
        </BarChart>
      );
      break;
    case VisualizationType.LINE:
    default:
      chart = (
        <LineChart data={data} ref={visualizationRef} margin={{ top: 20, left: 20, right: 50 }}>
          <Legend verticalAlign="top" height={50} iconSize={30} />
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="mmYYYY" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line
            type="linear"
            dataKey="numberOfQueries"
            strokeWidth={3}
            fill="#5182BB"
            stroke="#5182BB"
            name="Number of queries"
          />
        </LineChart>
      );
      break;
  }

  return (
    <div style={{ backgroundColor: "white" }}>
      <ResponsiveContainer width="100%" height={600} margin={{ right: 50 }}>
        {chart}
      </ResponsiveContainer>
    </div>
  );
};
