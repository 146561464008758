import { Button, Col, Divider, Layout, Row } from "antd";
import React from "react";
import { useBoolean } from "usehooks-ts";
import { MonthlyQueriesSection } from "../../organisms/MonthlyQueriesSection/MonthlyQueriesSection";
import { QueriesBreakdownSection } from "../../organisms/QueriesBreakdownSection/QueriesBreakdownSection";
import { QueriesPerUserSection } from "../../organisms/QueriesPerUserSection/QueriesPerUserSection";
import ReportGenerationModal from "../../organisms/ReportGenerationModal/ReportGenerationModal";
import SideNavigationMenu from "../../organisms/SideNavigationMenu/SideNavigationMenu";
import { TopFaultCodesSection } from "../../organisms/TopFaultCodesSection/TopFaultCodesSection";
import TopMenuNavigation from "../../organisms/TopMenuNavigation/TopMenuNavigation";
import { TopNlSearchesSection } from "../../organisms/TopNlSearchesSection/TopNlSearchesSection";
import { useDashboardPageStyles } from "./styles";
import { InformationLookupsSection } from "../../organisms/InformationLookupsSection/InformationLookupsSection";

const { Content } = Layout;

/**
 * The dashboard (usage report) page
 */
const DashboardPage = () => {
  const { value: modalVisible, setTrue: showModal, setFalse: hideModal } = useBoolean(false);

  const { mainContentCls } = useDashboardPageStyles();

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <TopMenuNavigation />
      <Layout>
        <SideNavigationMenu />
        <Content className={mainContentCls}>
          <Row align="middle" justify="end">
            <Button type="primary" onClick={showModal}>
              Generate report
            </Button>
          </Row>
          <Divider />
          <Row justify="center" gutter={[30, 60]}>
            <Col span={12}>
              <QueriesPerUserSection />
            </Col>
            <Col span={12}>
              <MonthlyQueriesSection />
            </Col>
            <Col span={12}>
              <QueriesBreakdownSection />
            </Col>
            <Col span={12}>
              <TopFaultCodesSection />
            </Col>
            <Col span={12} style={{marginRight:'auto'}}>
              <TopNlSearchesSection />
            </Col>
            {/* <Col span={12}>
              <InformationLookupsSection />
            </Col> */}
          </Row>
        </Content>
      </Layout>
      <ReportGenerationModal visible={modalVisible} onCancel={hideModal} />
    </Layout>
  );
};

export default DashboardPage;
