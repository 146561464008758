import { Divider } from "antd";
import React, { useEffect, useState } from "react";
import { useCurrentPng } from "recharts-to-png";
import { useBoolean } from "usehooks-ts";
import { VisualizationType } from "../../../consts/VisualizationType";
import { useQueriesBreakdown } from "../../../hooks/StatisticsHooks";
import { useStores } from "../../../stores";
import { HelperToolbar } from "../../molecules/HelperToolbar/HelperToolbar";
import { QueriesBreakdownVisualization } from "../../molecules/QueriesBreakdownVisualization/QueriesBreakdownVisualization";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';

/**
 * The queries breakdown section
 */
export const QueriesBreakdownSection = () => {
  const {
    reportGenerationStore: { addComponent }
  } = useStores();
  const { data } = useQueriesBreakdown();
  const { selectInDateRange } = useQueriesBreakdown();
  const [visualizationType, setVisualizationType] = useState(VisualizationType.BAR);
  const [getPng, { ref }] = useCurrentPng();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  // Add the queries breakdown chart image to the local storage
  useEffect(() => {
    const rows = [];
    if (data) {
      data?.forEach(({ name ,value}) => {
        rows.push(["Number of Queries:"+value]);
      });
    }
    addComponent({ type: "Photo", key: "Queries Breakdown",group: "queriesBreakdown", getPng: getPng });
    addComponent({
      type: "Table",
      key: "Queries Breakdown Table",
      group: "queriesBreakdown",
      columns: [ "Total NL Search" , "Total Fault Codes" ],
      rows: [rows],
      tableShow:false
    });
  }, [data]);

  // Handle date range changes
  const onDateRangeChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
    selectInDateRange(start, end);
  };

  // Handle changing the chart type to display
  const onVisualizationTypeSelect = (type) => {
    setVisualizationType(type);
  };

  const { value: modalVisible, setTrue: showModal, setFalse: hideModal } = useBoolean();

  return (
    <>
      <HelperToolbar
        title="Queries Breakdown"
        visualizationTypeOptions={[VisualizationType.BAR, VisualizationType.PIE]}
        onDateRangeChange={onDateRangeChange}
        onVisualizationTypeSelect={onVisualizationTypeSelect}
        onFullscreenIconClick={showModal}
      />
      <Divider />
      <QueriesBreakdownVisualization
        visualizationType={visualizationType}
        visualizationRef={ref}
        startDate={startDate}
        endDate={endDate}
      />
      {modalVisible && (
        <Modal
        open={modalVisible}
        onClose={hideModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
           <div style={{ transform:'scale(0.78)'}}>
        <Box sx={{position: 'absolute', top:'64vh',left: '50%', transform: 'translate(-50%, -50%)', width: '80%',bgcolor: 'background.paper',border: '2px solid #000',boxShadow: 24,p: 4,}}>
        <QueriesBreakdownVisualization
            visualizationType={visualizationType}
            startDate={startDate}
            endDate={endDate} />
         <Stack spacing={2} direction="row" sx={{float:'right' ,paddingY:'10px'}}>
         <div style={{paddingTop:'30px'}}>
          <Button variant="contained" style={{marginRight:'5px'}} onClick={hideModal}>OK</Button>
         </div>
       </Stack>
        </Box>
        </div>
      </Modal>
      )}
    </>
  );
};