import { Divider } from "antd";
import React, { useEffect, useState } from "react";
import { useBoolean } from "usehooks-ts";
import { useTopFaultCodes } from "../../../hooks/StatisticsHooks";
import { useStores } from "../../../stores";
import { HelperToolbar } from "../../molecules/HelperToolbar/HelperToolbar";
import { TopFaultCodesTable } from "../../molecules/TopFaultCodesTable/TopFaultCodesTable";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';

/**
 * The top fault code section
 */
export const TopFaultCodesSection = () => {
  const {
    reportGenerationStore: { addComponent }
  } = useStores();
  const { data, selectInDateRange } = useTopFaultCodes();
  const { value: modalVisible, setTrue: showModal, setFalse: hideModal } = useBoolean();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  // Add the total fault code table to the local storage
  useEffect(() => {
    if (data) {
      const rows = [];
      data.forEach(({ code, numberOfTimes, percentage }) => {
        rows.push([code, numberOfTimes, percentage]);
      });
      addComponent({
        type: "Table",
        key: "Top 20 Fault Codes",
        columns: ["Fault Code", "Number of times", "Percentage"],
        rows: rows,
        tableShow: true
      });
    }
  }, [data]);

  // Handle date range changes
  const onDateRangeChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
    selectInDateRange(start, end);
  };

  return (
    <>
      <HelperToolbar
        title="Top 20 Fault Codes"
        onDateRangeChange={onDateRangeChange}
        onFullscreenIconClick={showModal}
      />
      <Divider />
      <TopFaultCodesTable startDate={startDate} endDate={endDate} />
      {modalVisible && (
        <Modal
        open={modalVisible}
        onClose={hideModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
          <div style={{ transform:'scale(0.78)'}}>
        <Box sx={{position: 'absolute', top:'68vh',left: '50%', transform: 'translate(-50%, -50%)', width: '80%',bgcolor: 'background.paper',border: '2px solid #000',boxShadow: 24,p: 4,}}>
        <TopFaultCodesTable startDate={startDate} endDate={endDate} />
         <Stack spacing={2} direction="row" sx={{float:'right' ,paddingY:'10px'}}>
         <div style={{paddingTop:'30px'}}>
          <Button variant="contained" style={{marginRight:'5px'}} onClick={hideModal}>OK</Button>
         </div>
       </Stack>
        </Box>
        </div>
      </Modal>
      )}
    </>
  );
};